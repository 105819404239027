<script setup>

const props = defineProps({
    article: Object,
    image: Boolean,
    horizontal: Boolean,
    main: Boolean,
    bgColor: String,
});

const truncatedTitle = computed(() => {
    const title = props.article.title;

    if (!title) return;

    const maxLength = 47;

    return title.length > maxLength
        ? title.substring(0, maxLength - 3) + "..."
        : title;
});

const truncatedExcerpt = computed(() => {
    const excerpt = props.article.excerpt;

    if (!excerpt) return;

    const maxLength = 91;

    return excerpt.length > maxLength
        ? excerpt.substring(0, maxLength - 3) + "[...]"
        : excerpt;
});
</script>

<template>
    <NuxtLink :class="`${horizontal ? 'max-h-48' : ''}`" :to="`/articles/${article.slug}`">
        <span
            class="flex rounded-lg overflow-hidden border border-black16 border-solid block"
            :class="`${horizontal ? 'flex-row max-h-[inherit]' : 'flex-col'}`"
        >
            <img
                v-if="image"
                :class="[horizontal ? 'w-2/5' : '', main ? 'h-80' : '']"
                :src="article.image"
            />
            <span
                v-else
                class="h-60 p-4 relative block"
                :style="`background: ${article.background}`"
            >
                <Tag tagClass="bg-tagblack text-white">{{ article.tag }}</Tag>
                <svg-icon
                    class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-32"
                    :name="`articles/${article.svg}`"
                />
            </span>

            <span class="block p-4" :class="[horizontal ? '' : 'lg:p-6', bgColor]">
                <h3>{{ truncatedTitle }}</h3>

                <p
                    class="mt-2 block border-b border-solid border-gray-300 pb-4 text-black60"
                >
                    {{ truncatedExcerpt }}
                </p>
                <span class="flex justify-between items-center mt-4">
                    <span class="inline-flex items-center">
                        <span class="small font-medium me-4 inline-block">
                            <span>By:</span>
                            {{ article.by }}</span
                        >
                        <span class="inline-flex items-center">
                            <span class="text-black inline-block me-2">
                                <svg-icon name="common/clock" class="w-3 h-3" />
                            </span>
                            <span class="small font-medium"
                                >{{ article.reading_time }} min. read</span
                            >
                        </span>
                    </span>
                    <span class="text-black">
                        <svg-icon name="common/arrow" class="w-2.5 h-3" />
                    </span>
                </span>
            </span>
        </span>
    </NuxtLink>
</template>
